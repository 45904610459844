/* Styles for Contact Section */
#home-contact-section {
    background-color: var(--switch1-theme);
    color: var(--theme-color);
}

/* Styles for email section */
#email-section {
    margin-left: 25%;
    margin-right: 25%;
}

#email-section h3 {
    text-align: center;
    font-size: 3rem;
}

#email-section textarea {
    height: 100px;
}

.form-group label {
    font-size: 150%;
}

.socials-icon {
    height: 45px;
    width: 45px;
}