/* Skills Section Styling */
#home-skills-section {
    background-color: var(--switch1-theme);
}

#skills-title {
    color: var(--theme-color);
}

/* Styles for skill icon container */
.skill-section-title {
    color: var(--theme-color);
    margin-bottom: 4%;
    font-size: 2.7em;
    font-weight: bold;
    text-underline-offset: 5%;
}

#skills-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills-icons img {
    height: 45px;
    width: 45px;
    margin-left: 3%;
    margin-right: 3%;
}

.skills-sub-container {
    margin-bottom: 5%;
}