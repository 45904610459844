/* Project Section Styling */
#home-projects-section {
    background-color: var(--switch2-theme);
}

#projects-title {
    color: #000;
}

.project-title {
    font-weight: bold;
}

.github-repo-link,
.deployed-app-link {
    text-decoration: none;
    text-align: center !important;
    font-size: 1.2em;
}

.project-description {
    text-align: justify;
}